import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { gsap } from "gsap"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@reach/disclosure"

const primaryNavLinks = [
  {
    text: "about",
    url: "/about",
  },
  {
    text: "work",
    url: "/work",
  },
  {
    text: "thinkin'",
    url: "/blog",
  },
  {
    text: "connect",
    url: "/contact",
  },
]

const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  let overlay, slideInMenu, offCanvMainMenuItems, offCanvSecMenuItems
  let menuAnimationTimeline = useRef(
    gsap.timeline({
      paused: true,
    })
  )

  useEffect(() => {
    menuAnimationTimeline.current
      .addLabel("start")
      .to(overlay, {
        opacity: 1,
        display: "block",
        duration: 0.3,
      })
      .to(
        slideInMenu,
        {
          right: 0,
          duration: 0.5,
          ease: "expo.out",
        },
        "start"
      )
      .to(
        offCanvMainMenuItems.querySelectorAll("li"),
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.05,
          ease: "power1.out",
        },
        "<0.5"
      )
      .to(
        offCanvSecMenuItems.querySelectorAll(
          ".js-sec-menu > li, .js-menu-subscribe, .js-menu-enquiries, .js-menu-social > a"
        ),
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.05,
          ease: "power1.out",
        },
        "<"
      )
  }, [])

  const handleMenu = () => {
    menuAnimationTimeline.current.play()
    menuAnimationTimeline.current.reversed(menuIsOpen)
    setMenuIsOpen(!menuIsOpen)
  }

  return (
    <header className="absolute w-full z-50">
      <div className="px-4 lg:px-0 mx-auto py-5 flex items-center w-full lg:w-11/12">
        <Link to="/" className="z-50 mix-blend-difference">
          <StaticImage
            src="../assets/images/logo-white.png"
            width={36}
            quality={100}
            alt="braudit logo"
            formats={["png"]}
            placeholder="tracedSVG"
          />
        </Link>
        <nav className="hidden lg:block ml-28">
          <ul className="flex space-x-10 text-lg">
            {primaryNavLinks.map(link => (
              <li key={link.text}>
                <Link to={link.url} className="anim-link-effect-1">
                  <span data-hover={link.text}>{link.text}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Button onClick={handleMenu} isOpen={menuIsOpen} />
        <div
          className={classNames(
            "opacity-0 hidden fixed left-0 top-0 w-screen h-screen bg-black"
          )}
          style={{
            ["--tw-bg-opacity"]: "0.7",
          }}
          ref={el => {
            overlay = el
          }}
          onClick={() => handleMenu()}
        ></div>
        <div
          className={classNames(
            "fixed -right-[100vw] flex items-center justify-center lg:block lg:-right-96 top-0 bg-white h-screen w-screen lg:w-96 pl-4 lg:pl-8 pt-20"
          )}
          ref={el => {
            slideInMenu = el
          }}
        >
          <nav className="text-black lg:mt-7 flex justify-center lg:justify-start space-x-4 sm:space-x-6 lg:space-x-0">
            <ul
              className="flex flex-col space-y-6 lg:hidden"
              ref={el => {
                offCanvMainMenuItems = el
              }}
            >
              {primaryNavLinks.map(link => (
                <li key={link.text} className="translate-y-2 opacity-0">
                  <Link
                    to={link.url}
                    className="font-headings font-light text-2xl capitalize"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
            <div
              className="px-5 font-body text-base sm:text-xl"
              ref={el => {
                offCanvSecMenuItems = el
              }}
            >
              <ul className="js-sec-menu flex flex-col space-y-7 lg:space-y-5">
                <li className="translate-y-2 opacity-0">
                  <Disclosure>
                    <DisclosureButton className="sideNav-disclosure flex items-center">
                      Our Partners{" "}
                      <img src="/chevron.svg" className="ml-3 w-[8px]" alt="" />
                    </DisclosureButton>
                    <DisclosurePanel>
                      <ul className="flex flex-col space-y-5 pt-3 pl-3 text-[#666]">
                        <li>
                          <a target="_blank" href="https://tscdesign.co">
                            The Sole Company
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://tsk.pixieset.com/">
                            TSK
                          </a>
                        </li>
                      </ul>
                    </DisclosurePanel>
                  </Disclosure>
                </li>
                <li className="translate-y-2 opacity-0">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="translate-y-2 opacity-0">
                  <Link to="/terms-of-content-use">Terms of Content Use</Link>
                </li>
                <li className="translate-y-2 opacity-0">
                  <Link to="#">Sitemap</Link>
                </li>
              </ul>
              <Link to="/contact" className="block">
                <button className="js-menu-enquiries translate-y-2 opacity-0 bg-black px-12 sm:px-14 py-4 mt-12 text-white text-sm sm:text-base">
                  Enquiries
                </button>
              </Link>
              <Link
                to="/contact#subscribe"
                className="js-menu-subscribe translate-y-2 opacity-0  block w-fit text-[#666] text-sm sm:text-base mt-8 lg:mt-12 pb-2 lg:pb-3 border-b border-[#666]"
              >
                Subscribe for insights
              </Link>
              <div className="js-menu-social flex space-x-4 mt-10">
                <a
                  href="https://www.instagram.com/braudit.co/"
                  target="_blank"
                  className="flex items-end translate-y-2 opacity-0"
                >
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.75 1H7.25C3.79822 1 1 3.79822 1 7.25V19.75C1 23.2018 3.79822 26 7.25 26H19.75C23.2018 26 26 23.2018 26 19.75V7.25C26 3.79822 23.2018 1 19.75 1Z"
                      stroke="#999999"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.4996 12.7129C18.6539 13.7532 18.4762 14.8156 17.9918 15.7491C17.5075 16.6826 16.7411 17.4396 15.8017 17.9125C14.8623 18.3853 13.7977 18.5499 12.7594 18.3828C11.721 18.2157 10.7618 17.7255 10.0182 16.9818C9.27453 16.2382 8.7843 15.279 8.61722 14.2406C8.45014 13.2023 8.61472 12.1377 9.08754 11.1983C9.56037 10.2589 10.3174 9.49255 11.2509 9.00818C12.1844 8.5238 13.2468 8.34611 14.2871 8.50038C15.3483 8.65773 16.3307 9.15221 17.0893 9.91076C17.8478 10.6693 18.3423 11.6517 18.4996 12.7129Z"
                      stroke="#999999"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.375 6.625H20.3875"
                      stroke="#999999"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/brauditcreatives/"
                  target="_blank"
                  className="translate-y-2 opacity-0"
                >
                  <svg
                    width="32"
                    height="31"
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.9999 10.248C24.3871 10.248 26.6765 11.1964 28.3645 12.8843C30.0525 14.5723 31.0008 16.8617 31.0008 19.2489V29.7499H25.0002V19.2489C25.0002 18.4532 24.6841 17.6901 24.1214 17.1274C23.5588 16.5647 22.7956 16.2486 21.9999 16.2486C21.2042 16.2486 20.441 16.5647 19.8784 17.1274C19.3157 17.6901 18.9996 18.4532 18.9996 19.2489V29.7499H12.999V19.2489C12.999 16.8617 13.9473 14.5723 15.6353 12.8843C17.3233 11.1964 19.6127 10.248 21.9999 10.248V10.248Z"
                      stroke="#999999"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.00059 11.7471H1V29.7488H7.00059V11.7471Z"
                      stroke="#999999"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.00029 7.24766C5.65731 7.24766 7.00059 5.90438 7.00059 4.24736C7.00059 2.59035 5.65731 1.24707 4.00029 1.24707C2.34328 1.24707 1 2.59035 1 4.24736C1 5.90438 2.34328 7.24766 4.00029 7.24766Z"
                      stroke="#999999"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

const Button = ({ isOpen, onClick }) => {
  return (
    <button
      className={`c-menu-btn z-50 ml-auto ${isOpen ? "c-is-open" : ""}`}
      onClick={onClick}
      aria-label="Open menu"
      aria-expanded={isOpen ? "true" : "false"}
    >
      <span className="c-line"></span>
      <span className="c-line"></span>
      <span className="c-line"></span>
    </button>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
