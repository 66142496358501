import React from "react"
import { Link } from "gatsby"

import { FadeInTopList, FadeInRight, FadeInTop } from "./animations"

const footerLinks = [
  {
    text: "Braudit",
    link: "/about",
  },
  {
    text: "Expertise & Solutions",
    link: "/work",
  },
  {
    text: "Thinking",
    link: "/blog",
  },
  {
    text: "Connect",
    link: "/contact",
  },
  {
    text: "Terms of Content Use",
    link: "/terms-of-content-use",
  },
  {
    text: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    text: "Ethical Policy",
    link: "/ethical-policy",
  },
  {
    text: "Sitemap",
    link: "#",
  },
]

const Footer = () => {
  return (
    <section className="pt-12 lg:pt-14">
      <div className="c-container-full pb-12 lg:pb-16 lg:flex">
        <FadeInTop>
          <div className=" pb-12 lg:w-5/12">
            <Link to="/">
              <svg
                width="107"
                height="25"
                viewBox="0 0 107 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.45309 9.80454C5.02564 8.96385 5.81023 8.28079 6.80687 7.75536C7.82472 7.22993 8.98041 6.96721 10.2739 6.96721C11.7795 6.96721 13.1366 7.33502 14.3453 8.07062C15.5752 8.80622 16.5401 9.85708 17.2398 11.2232C17.9608 12.5683 18.3213 14.1341 18.3213 15.9206C18.3213 17.707 17.9608 19.2938 17.2398 20.681C16.5401 22.0471 15.5752 23.1084 14.3453 23.8651C13.1366 24.6217 11.7795 25 10.2739 25C8.9592 25 7.80352 24.7478 6.80687 24.2434C5.83143 23.7179 5.04684 23.0454 4.45309 22.2257V24.7163H0V1.38714H4.45309V9.80454ZM13.7728 15.9206C13.7728 14.8697 13.5501 13.966 13.1048 13.2093C12.6807 12.4317 12.1082 11.8432 11.3872 11.4439C10.6874 11.0446 9.92404 10.8449 9.09704 10.8449C8.29124 10.8449 7.52785 11.0551 6.80687 11.4754C6.1071 11.8747 5.53456 12.4632 5.08925 13.2409C4.66515 14.0185 4.45309 14.9327 4.45309 15.9836C4.45309 17.0345 4.66515 17.9487 5.08925 18.7264C5.53456 19.504 6.1071 20.103 6.80687 20.5233C7.52785 20.9227 8.29124 21.1223 9.09704 21.1223C9.92404 21.1223 10.6874 20.9121 11.3872 20.4918C12.1082 20.0715 12.6807 19.4725 13.1048 18.6948C13.5501 17.9172 13.7728 16.9924 13.7728 15.9206Z"
                  fill="#666666"
                />
                <path
                  d="M24.42 9.96217C24.9925 9.03741 25.7347 8.31232 26.6465 7.78689C27.5795 7.26145 28.6398 6.99874 29.8273 6.99874V11.633H28.6504C27.2508 11.633 26.1906 11.9588 25.4696 12.6103C24.7698 13.2619 24.42 14.3968 24.42 16.0151V24.7163H19.9669V7.25095H24.42V9.96217Z"
                  fill="#666666"
                />
                <path
                  d="M30.0912 15.9206C30.0912 14.1551 30.4411 12.5893 31.1408 11.2232C31.8618 9.85708 32.8267 8.80622 34.0354 8.07062C35.2653 7.33502 36.633 6.96721 38.1386 6.96721C39.4533 6.96721 40.5984 7.22993 41.5738 7.75536C42.5705 8.28079 43.3657 8.94283 43.9594 9.74149V7.25095H48.4443V24.7163H43.9594V22.1627C43.3869 22.9823 42.5917 23.6654 41.5738 24.2119C40.5772 24.7373 39.4215 25 38.1068 25C36.6224 25 35.2653 24.6217 34.0354 23.8651C32.8267 23.1084 31.8618 22.0471 31.1408 20.681C30.4411 19.2938 30.0912 17.707 30.0912 15.9206ZM43.9594 15.9836C43.9594 14.9117 43.7473 13.9975 43.3232 13.2409C42.8991 12.4632 42.3266 11.8747 41.6056 11.4754C40.8846 11.0551 40.1107 10.8449 39.2836 10.8449C38.4566 10.8449 37.6933 11.0446 36.9935 11.4439C36.2937 11.8432 35.7212 12.4317 35.2759 13.2093C34.8518 13.966 34.6397 14.8697 34.6397 15.9206C34.6397 16.9714 34.8518 17.8962 35.2759 18.6948C35.7212 19.4725 36.2937 20.0715 36.9935 20.4918C37.7145 20.9121 38.4779 21.1223 39.2836 21.1223C40.1107 21.1223 40.8846 20.9227 41.6056 20.5233C42.3266 20.103 42.8991 19.5145 43.3232 18.7579C43.7473 17.9802 43.9594 17.0555 43.9594 15.9836Z"
                  fill="#666666"
                />
                <path
                  d="M67.8386 7.25095V24.7163H63.3537V22.5095C62.7812 23.2661 62.0284 23.8651 61.0954 24.3064C60.1835 24.7268 59.1869 24.9369 58.1054 24.9369C56.7271 24.9369 55.5078 24.6532 54.4475 24.0858C53.3873 23.4973 52.5497 22.6461 51.9347 21.5322C51.341 20.3972 51.0441 19.0521 51.0441 17.4968V7.25095H55.4972V16.8663C55.4972 18.2535 55.8471 19.3253 56.5468 20.082C57.2466 20.8176 58.2008 21.1854 59.4095 21.1854C60.6394 21.1854 61.6043 20.8176 62.3041 20.082C63.0038 19.3253 63.3537 18.2535 63.3537 16.8663V7.25095H67.8386Z"
                  fill="#666666"
                />
                <path
                  d="M69.4968 15.9206C69.4968 14.1551 69.8467 12.5893 70.5465 11.2232C71.2675 9.85708 72.2429 8.80622 73.4728 8.07062C74.7027 7.33502 76.0705 6.96721 77.576 6.96721C78.7211 6.96721 79.8132 7.21942 80.8522 7.72383C81.8913 8.20723 82.7183 8.85876 83.3332 9.67844V1.38714H87.85V24.7163H83.3332V22.1311C82.7819 22.9929 82.0079 23.6864 81.0113 24.2119C80.0146 24.7373 78.8589 25 77.5442 25C76.0599 25 74.7027 24.6217 73.4728 23.8651C72.2429 23.1084 71.2675 22.0471 70.5465 20.681C69.8467 19.2938 69.4968 17.707 69.4968 15.9206ZM83.3651 15.9836C83.3651 14.9117 83.153 13.9975 82.7289 13.2409C82.3048 12.4632 81.7323 11.8747 81.0113 11.4754C80.2903 11.0551 79.5163 10.8449 78.6893 10.8449C77.8623 10.8449 77.0989 11.0446 76.3991 11.4439C75.6994 11.8432 75.1268 12.4317 74.6815 13.2093C74.2574 13.966 74.0454 14.8697 74.0454 15.9206C74.0454 16.9714 74.2574 17.8962 74.6815 18.6948C75.1268 19.4725 75.6994 20.0715 76.3991 20.4918C77.1201 20.9121 77.8835 21.1223 78.6893 21.1223C79.5163 21.1223 80.2903 20.9227 81.0113 20.5233C81.7323 20.103 82.3048 19.5145 82.7289 18.7579C83.153 17.9802 83.3651 17.0555 83.3651 15.9836Z"
                  fill="#666666"
                />
                <path
                  d="M92.8671 5.17024C92.0826 5.17024 91.4252 4.92854 90.8951 4.44515C90.3861 3.94073 90.1317 3.32072 90.1317 2.58512C90.1317 1.84952 90.3861 1.24002 90.8951 0.75662C91.4252 0.252207 92.0826 0 92.8671 0C93.6517 0 94.2985 0.252207 94.8074 0.75662C95.3375 1.24002 95.6026 1.84952 95.6026 2.58512C95.6026 3.32072 95.3375 3.94073 94.8074 4.44515C94.2985 4.92854 93.6517 5.17024 92.8671 5.17024ZM95.0619 7.25095V24.7163H90.6088V7.25095H95.0619Z"
                  fill="#666666"
                />
                <path
                  d="M103.056 10.8764V19.3253C103.056 19.9138 103.194 20.3447 103.469 20.6179C103.766 20.8701 104.254 20.9962 104.932 20.9962H107V24.7163H104.201C100.448 24.7163 98.5709 22.9088 98.5709 19.2938V10.8764H96.4716V7.25095H98.5709V2.9319H103.056V7.25095H107V10.8764H103.056Z"
                  fill="#666666"
                />
              </svg>
            </Link>
          </div>
        </FadeInTop>
        <div className="sm:flex lg:ml-7">
          <ul className="space-y-5 leading-none text-[#999] mb-12">
            <FadeInTopList>
              <li>General Enquiries</li>
              <li>
                <a className="text-white" href="mailto:hello@braudit.co">
                  hello@braudit.co
                </a>
              </li>
            </FadeInTopList>
          </ul>
          <div className="sm:ml-36">
            <ul className="space-y-5 leading-none text-[#999] mb-12">
              <FadeInTopList>
                {footerLinks.map(({ text, link }) => (
                  <li key={text}>
                    <Link to={link}>{text}</Link>
                  </li>
                ))}
              </FadeInTopList>
            </ul>
            <FadeInRight delay={0.5}>
              <div className="c-pre-fade-in-right flex space-x-4 mt-10">
                <a
                  href="https://www.instagram.com/braudit.co/"
                  target="_blank"
                  className="flex items-end"
                >
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.75 1H7.25C3.79822 1 1 3.79822 1 7.25V19.75C1 23.2018 3.79822 26 7.25 26H19.75C23.2018 26 26 23.2018 26 19.75V7.25C26 3.79822 23.2018 1 19.75 1Z"
                      stroke="#999999"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.4996 12.7129C18.6539 13.7532 18.4762 14.8156 17.9918 15.7491C17.5075 16.6826 16.7411 17.4396 15.8017 17.9125C14.8623 18.3853 13.7977 18.5499 12.7594 18.3828C11.721 18.2157 10.7618 17.7255 10.0182 16.9818C9.27453 16.2382 8.7843 15.279 8.61722 14.2406C8.45014 13.2023 8.61472 12.1377 9.08754 11.1983C9.56037 10.2589 10.3174 9.49255 11.2509 9.00818C12.1844 8.5238 13.2468 8.34611 14.2871 8.50038C15.3483 8.65773 16.3307 9.15221 17.0893 9.91076C17.8478 10.6693 18.3423 11.6517 18.4996 12.7129Z"
                      stroke="#999999"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.375 6.625H20.3875"
                      stroke="#999999"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/brauditcreatives/"
                  target="_blank"
                  className=""
                >
                  <svg
                    width="32"
                    height="31"
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.9999 10.248C24.3871 10.248 26.6765 11.1964 28.3645 12.8843C30.0525 14.5723 31.0008 16.8617 31.0008 19.2489V29.7499H25.0002V19.2489C25.0002 18.4532 24.6841 17.6901 24.1214 17.1274C23.5588 16.5647 22.7956 16.2486 21.9999 16.2486C21.2042 16.2486 20.441 16.5647 19.8784 17.1274C19.3157 17.6901 18.9996 18.4532 18.9996 19.2489V29.7499H12.999V19.2489C12.999 16.8617 13.9473 14.5723 15.6353 12.8843C17.3233 11.1964 19.6127 10.248 21.9999 10.248V10.248Z"
                      stroke="#999999"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.00059 11.7471H1V29.7488H7.00059V11.7471Z"
                      stroke="#999999"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.00029 7.24766C5.65731 7.24766 7.00059 5.90438 7.00059 4.24736C7.00059 2.59035 5.65731 1.24707 4.00029 1.24707C2.34328 1.24707 1 2.59035 1 4.24736C1 5.90438 2.34328 7.24766 4.00029 7.24766Z"
                      stroke="#999999"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </FadeInRight>
          </div>
        </div>
      </div>
      <div className="bg-[#090909] py-7">
        <div className="c-container-full sm:flex justify-between">
          <FadeInTop>
            <p className=" mb-12">
              ©2022 Braudit Solutions. All rights reserved
            </p>
          </FadeInTop>
          <FadeInTop delay={0.3}>
            <p className=" text-[#666] text-right text-sm">
              Developed by{" "}
              <b>
                <a href="https://tscdesign.co" target="_blank">
                  Sole Company
                </a>
              </b>
            </p>
          </FadeInTop>
        </div>
      </div>
    </section>
  )
}

export default Footer
