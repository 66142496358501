import React from "react"
import { ScrollTrigger } from "gsap/all"
import gsap from "gsap"
import { useRef, useEffect } from "react"

import { Tween, Reveal } from "react-gsap"

gsap.registerPlugin(ScrollTrigger)

const FadeInTop = ({ children, delay, threshold }) => {
  if (children.length > 1) {
    throw Error(
      "Component expects only one child. If you have more than one element, make sure to wrap them individually"
    )
  }
  let newEl = React.cloneElement(children, {
    className:
      children?.props?.className + ` transform opacity-0 translate-y-4`,
  })

  return (
    <Reveal threshold={threshold}>
      <Tween to={{ y: 0, opacity: 1, duration: 1, delay }} ease="power1.out">
        {newEl}
      </Tween>
    </Reveal>
  )
}

FadeInTop.defaultProps = {
  threshold: 0.45,
  delay: 0,
}

const FadeInRight = ({ children, delay, threshold }) => {
  if (children.length > 1) {
    throw Error(
      "Component expects only one child. If you have more than one child, make sure to wrap them individually"
    )
  }
  let newEl = React.cloneElement(children, {
    className: children.props.className + ` transform opacity-0 -translate-x-4`,
  })

  return (
    <Reveal threshold={threshold}>
      <Tween
        to={{ x: 0, opacity: 1, duration: 1, delay: delay ? delay : null }}
        ease="power1.out"
      >
        {newEl}
      </Tween>
    </Reveal>
  )
}

FadeInRight.defaultProps = {
  threshold: 0.45,
  delay: 0,
}

const FadeIn = ({ children, delay, threshold }) => {
  if (children.length > 1) {
    throw Error(
      "Component expects only one child. If you have more than one child, make sure to wrap them individually"
    )
  }
  let newEl = React.cloneElement(children, {
    className: children.props.className + ` opacity-0 `,
  })

  return (
    <Reveal threshold={threshold}>
      <Tween
        to={{ opacity: 1, duration: 1, delay: delay ? delay : null }}
        ease="power1.out"
      >
        {newEl}
      </Tween>
    </Reveal>
  )
}

FadeIn.defaultProps = {
  threshold: 0.45,
  delay: 0,
}

const FadeInTopList = ({ children, delay }) => {
  const els = []
  const newEls = React.Children.map(children, child => {
    return React.cloneElement(child, { ref: el => els.push(el) })
  })

  //hmmm... I should be using useLayoutEffect here but somehow
  // this is working...
  useEffect(() => {
    ;[...els].forEach(el => {
      el.classList.add("opacity-0", "translate-y-4")
    })

    const scrollAnim = ScrollTrigger.create({
      trigger: els[0],
      start: "50% bottom",
      onEnter: () => {
        gsap.to(els, {
          y: 0,
          opacity: 1,
          stagger: 0.1,
          delay,
          ease: "power1.out",
        })
      },
    })

    return () => {
      scrollAnim.kill()
    }
  }, [])

  return <>{newEls}</>
}

FadeInTopList.defaultProps = {
  delay: 0.3,
}

// to be used in page components to register image scroll reveal animations
// for elements with appropriate class name
const useScrollImageReveal = () => {
  useEffect(() => {
    let imgRevealContainer = document.querySelectorAll(".js-img-reveal")
    imgRevealContainer.forEach(container => {
      let imgRevealImg = container.querySelector(".gatsby-image-wrapper")
      if (!container || !imgRevealImg) return
      console.log({ imgRevealContainer, imgRevealImg })

      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container,
            start: "20% bottom",
          },
        })
        .set(container, { autoAlpha: 1 })
        .to(container, {
          duration: 2,
          x: 0,
          ease: "power3.out",
        })
        .to(
          imgRevealImg,
          {
            duration: 2,
            x: 0,
            scale: 1,
            ease: "power3.out",
          },
          "<"
        )
    })
  }, [])
}

export {
  gsap,
  ScrollTrigger,
  FadeInTop,
  FadeInTopList,
  FadeInRight,
  FadeIn,
  Reveal,
  useScrollImageReveal,
}
