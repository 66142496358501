import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"

import { FadeIn, gsap } from "."

const CookieNotice = () => {
  const [isShown, setIsShown] = useState(
    () =>
      typeof window !== "undefined" &&
      window.localStorage.getItem("cookiesConsentClosed") !== "yes"
  )

  const handleCloseCookieConsent = () => {
    //animate cookie consent pop-up out
    gsap.to(".js-cookie-consent-pop-up", {
      duration: 0.6,
      opacity: 0,
      onComplete: () => {
        // disable cookie consent pop-up
        setIsShown(false)
        typeof window !== "undefined" &&
          window.localStorage.setItem("cookiesConsentClosed", "yes")
      },
    })
  }

  return (
    isShown && (
      <FadeIn
      // delay={3}
      >
        <div className="js-cookie-consent-pop-up fixed bottom-0 z-50 w-full h-auto bg-white border-4 border-black p-4">
          <button
            className="absolute top-4 right-4"
            onClick={handleCloseCookieConsent}
          >
            <svg className="w-4" viewBox="1 1 24 24" width="24" height="24">
              <path
                d="M25 1L1 25"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 1L25 25"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
          <div className="lg:w-11/12 mx-auto lg:flex justify-between items-center font-headings px-4 lg:px-0 pt-6 lg:pt-0">
            <p className="flex-auto text-center lg:text-left text-black text-lg  lg:pr-8">
              Cookies allow us to improve your experience on our site. By
              continuing to browse, you agree to the storing of cookies on your
              device. To learn more about the cookies we use and how we use
              them, please see the “Use of Cookies” section in our{" "}
              <Link className="underline" to="/privacy-policy">
                Privacy Policy
              </Link>
              .
            </p>
            <Link
              to="/privacy-policy"
              className="w-full lg:w-max text-center flex-grow-0 flex-shrink-0 block mt-6 lg:mt-0 bg-black px-9 py-4 text-white leading-none"
            >
              More Info
            </Link>
          </div>
        </div>
      </FadeIn>
    )
  )
}

export default CookieNotice
